<template>
<v-app-bar app>
    <v-card color="lighten-4" :class="['horizontal-nav',{'horizontal-nav-ltr': !$vuetify.rtl},{'horizontal-nav-rtl' : $vuetify.rtl}]" flat rounded height="50px" :absolute="true" :bottom="false">
        <v-toolbar dense>
            <v-app-bar-nav-icon @click="closeDrawer" class="d-lg-none"></v-app-bar-nav-icon>
     

            <v-spacer></v-spacer>
            <!-- Langauges -->

            <!-- Notification -->
            <div class="top-header-block">
                <!-- <v-badge :content="1" :value="1" color="red" overlap style="font-size:12px">
                    <v-icon>
                        mdi-bell
                    </v-icon>
                </v-badge> -->
            </div>

            <!-- User Menu -->
            <div class="top-header-block">
                <v-menu offset-y :close-on-click="closeOnClick" class="user-menu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" class="user-nav-btn">
                            <div class="d-flex">
                                <div class="user-info" :style="$vuetify.rtl ? 'text-align:left;margin-left:10px' : 'text-align:right;margin-right:10px;padding:5px'">
                                    <div class="user-name secondary--text">Adham</div>
                                    <div class="user-title accent--text">Admin</div>
                                </div>  
                                <div class="user-img d-flex">
                                <img  src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y">
                                </div>
                            </div>
                        </v-btn>
                    </template>

                    <v-list class="user-menu-list">
                        <v-list-item link>
                            <v-list-item-icon>
                                <v-icon size="20">mdi-account</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>My Profile</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item link to="/coach/inbox">
                            <v-list-item-icon>
                                <v-icon size="20">mdi-message</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Messages</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>

                        <v-list-item link to="/coach/coach-setting">
                            <v-list-item-icon>
                                <v-icon size="20">mdi-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Settings</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                  
                    </v-list>
                </v-menu>
            </div>

        </v-toolbar>
    </v-card>
</v-app-bar>
</template>

    
<script>
export default {
    data() {
        return {
            closeOnClick: true,
            notivications_status: true,
            coachData: null
        }
    },

    computed: {
        availableLocales() {
            return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
        }
    },

    methods: {
   
        closeDrawer() {
            this.$emit('closedrawer');
        },
        toggleDirection() {
            this.$vuetify.rtl = !this.$vuetify.rtl

        },

    }
}
</script>

    
<style lang="scss" >
    .v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined){
        box-shadow: none !important;
    }
.v-menu__content {
   // top: 75px !important;
    z-index: 999 !important;
}

.user-nav-btn {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    padding: 5px !important;

    &::before {
        background: transparent !important;
        box-shadow: none !important;
    }

    .user-info {
        letter-spacing: 0;

        .user-name {
            text-transform: capitalize;
            font-size: 14px;
            font-weight: 500;
        }

        .user-title {
            font-size: 12px;
            text-transform: capitalize;
        }
    }

    .user-img {
        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
}

.user-menu-list {
    .v-list-item {
        min-height: 30px !important;

    }

    .v-list-item__icon:first-child {
        margin-right: 10px !important;
    }

    .v-list-item__icon {
        margin: 8px 0 !important;
    }

    .v-list-item__title {
        font-size: 0.7rem;
    }
}

.top-header-block {
    padding: 0 10px;
}

.langs {
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none !important;

    img {
        height: 14px;
        margin: 0 10px;
    }
}

.horizontal-nav {
    width: calc(100vw - (100vw - 100%) - 50px);

    border-radius: 0.428rem !important;
    margin: 1rem auto 0;

    transition: 300ms ease all;

    .v-toolbar--dense {
        border-radius: 0.428rem !important;
        transition: 300ms ease all;
    }
}

.horizontal-nav .v-card--flat {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
</style>
